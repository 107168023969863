/**
 * NextJS Client Routes ONLY
 * Do not add external links here
 * Use app.ts or constants/externalLinks for external links
 */

export const ROUTE_HOME = "/";

/*** Route to PUT a new user into .swoosh system ***/
export const ROUTE_CREATE_USER = "/create-user?callbackUrl=";

/*** Onboarding 1.5 ***/
export const ROUTE_WELCOME = "/welcome";
export const ROUTE_ONBOARDING = "/onboarding";
export const ROUTE_ONBOARDING_HANDLE_1_5 = "/onboarding/create-handle";
export const ROUTE_ONBOARDING_CARD_1_5 = "/onboarding/design-swoosh-id";
export const ROUTE_ONBOARDING_WALLET_1_5 = "/onboarding/set-up-wallet";
// wallet route to use from account/settings page
export const ROUTE_ONBOARDING_WALLET_SETTINGS_1_5 = `${ROUTE_ONBOARDING_WALLET_1_5}?settings=`;
export const ROUTE_ONBOARDING_MINT_1_5 = "/onboarding/craft-swoosh-id";
export const ROUTE_ONBOARDING_UPDATE_HANDLE = "/onboarding/update-handle";

/*** Profile ***/
export const ROUTE_ACCOUNT_SETTINGS = "/account/settings";
export const ROUTE_ACCOUNT_SETTINGS_WALLET = `${ROUTE_ACCOUNT_SETTINGS}?wallet=`;
export const ROUTE_ACCOUNT_SETTINGS_HANDLE = `${ROUTE_ACCOUNT_SETTINGS}?handle=`;

export const ROUTE_ORDER_HISTORY = "/account/orders";
export const ROUTE_PROFILE = "/profile/[handle]"; // Only to be used for development/test
export const ROUTE_SUBDOMAIN_PROFILE = `https://[handle].${process.env.NEXT_PUBLIC_SITE_HOSTNAME}`;

export const ROUTE_ORDERS_SUCCESS = "/orders/success/";
export const ROUTE_PROFILE_ACHIEVEMENTS = "/profile/[handle]/achievements";

/*** Product Routes ***/
export const ROUTE_REVEAL_COLLECTIONS = "/reveal/collections/[collectionName]";
export const ROUTE_COLLECTIONS = "/collections";
export const ROUTE_COLLECTION_DETAIL = "/collections/[collectionName]";
export const ROUTE_PRODUCT_DETAIL_PAGE =
  "/collections/[collectionName]/products/[productName]";
export const ROUTE_TOKEN_LISTING_PAGE =
  "/collections/[collectionName]/products/[productName]/tokens/[tokenId]";

/*** Marketplace */
export const ROUTE_GALLERY_COLLECTION_LISTING =
  "/gallery/collections/[collectionName]";

/*** Legal ***/
export const ROUTE_LEGAL_DIGITAL_COLLECTIBLE_TERMS =
  "/legal/digital-collectible-terms";
export const ROUTE_LEGAL_TERMS_OF_SERVICE = "/legal/terms-of-service";
export const ROUTE_LEGAL_PRIVACY_POLICY = "/legal/privacy-policy";
export const ROUTE_LEGAL_TERMS_OF_SALE = "/legal/terms-of-sale";
export const ROUTE_LEGAL_TERMS_OF_USE = "/legal/terms-of-use";

/*** Misc ***/
export const ROUTE_NOT_FOUND = "/404";
export const ROUTE_COUNTRY_NOT_SUPPORTED = "/location";

/*** Design System ***/
export const ROUTE_DESIGN_SYSTEM = "/design-system";
export const ROUTE_DESIGN_SYSTEM_COLORS = "/design-system/colors";
export const ROUTE_DESIGN_SYSTEM_TYPOGRAPHY = "/design-system/typography";
export const ROUTE_DESIGN_SYSTEM_TYPOGRAPHY_RESPONSIVE =
  "/design-system/typography/responsive";
export const ROUTE_DESIGN_SYSTEM_ICONOGRAPHY = "/design-system/icons";
export const ROUTE_DESIGN_SYSTEM_GRID = "/design-system/grid";
export const ROUTE_DESIGN_SYSTEM_BUTTONS = "/design-system/buttons";
export const ROUTE_DESIGN_SYSTEM_ICON_BUTTONS = "/design-system/icon-buttons";
export const ROUTE_DESIGN_SYSTEM_LINKS = "/design-system/links";
export const ROUTE_DESIGN_SYSTEM_PROPERTIES = "/design-system/properties";
export const ROUTE_DESIGN_SYSTEM_TABS = "/design-system/tabs";
export const ROUTE_DESIGN_SYSTEM_SPACING = "/design-system/spacing";
export const ROUTE_DESIGN_SYSTEM_CARDS = "/design-system/cards";
export const ROUTE_DESIGN_SYSTEM_GLOBAL_NAV = "/design-system/globalNav";
export const ROUTE_DESIGN_SYSTEM_PRODUCT_CARDS = "/design-system/product-cards";
export const ROUTE_DESIGN_SYSTEM_MODAL = "/design-system/modal";
export const ROUTE_DESIGN_SYSTEM_TABLES = "/design-system/tables";
export const ROUTE_DESIGN_TOOLTIPS = "/design-system/tooltips";
export const ROUTE_DESIGN_SYSTEM_TOAST = "/design-system/toast";
export const ROUTE_DESIGN_SYSTEM_MARKETING_BANNER =
  "/design-system/marketing-banners";

/** Opensea routes */
export const ROUTE_OPEN_SEA_3D_CARD =
  "/nft/collection/[chainId]/[contractAddress]/[tokenId]/animation";

/*** Marketing Page ***/
export const ROUTE_MARKETING_AF1_404 = "/m/air-force-1-low-404";
export const ROUTE_MARKETING_AF1_404_ERROR = "/m/air-force-1-low-404-error";
export const ROUTE_MARKETING_AM1_LOW_POLY = "/m/maxs-lab";

/*** Preorder ***/
export const ROUTE_PREORDER_SHOP_PRODUCT = "/shop/products/[productName]";
export const ROUTE_PREORDER_SHOP_PRODUCT_PENDING_ORDER =
  "/orders/sales/pending/[orderNumber]";
export const ROUTE_PREORDER_AIR_MAX_1_LOW_POLY =
  "/shop/products/air-max-1-low-poly";

export const ROUTE_DOCUMENT = "/d/[slug]";
export const ROUTE_PREORDER_SHOP_PRODUCT_ORDER_CONFIRMATION =
  "/orders/sales/[orderNumber]";

"use client";
import * as React from "react";
import NikeSvg from "@/app/components/NikeSvg";
import { Copy } from "./Copy";
import { Headline } from "./Headline";
import { GridBackground } from "../../../components/GridBackground";
import { GlobalFooterMinimal } from "@/src/components/GlobalElements/GlobalFooterMinimal";
import { Country } from "@/libs/geo/countries";

const CountryMessage: React.FC<{ country: string | undefined }> = ({
  country,
}) => {
  return (
    <GridBackground
      className="bg-gray-950 flex-1 flex-col text-cream"
      innerClassName="h-full"
    >
      <div className="flex h-full flex-col p-1 e justify-center">
        <div className=" laptop:ml-20 laptop:self-baseline flex basis-4/5 flex-col justify-center self-center">
          <NikeSvg classes="w-20 my-4" />
          <Headline size="md" className="my-6">
            IT&#39;S NOT YOU.
            <br />
            IT&#39;S US
          </Headline>
          <Copy size="base" className="laptop:max-w-xl my-6 max-w-md">
            .SWOOSH is not available in your location. Nike is required to
            comply with local regulations that can differ on a country by
            country basis. For this reason, .SWOOSH experiences are currently
            not supported in some countries.
          </Copy>

          <div className="flex h-auto flex-1 justify-end">
            <GlobalFooterMinimal country={country as Country} />
          </div>
        </div>
      </div>
    </GridBackground>
  );
};

export default CountryMessage;
